import * as R from 'ramda'
import { fetchZipcode, fetchZipcodeCalendar, fetchZipcodeCutoff } from '../api/backend'

export const REQUEST_ZIPCODE = 'REQUEST_ZIPCODE'
export const CLEAR_ZIPCODE = 'CLEAR_ZIPCODE'
export const CLEAR_ZIPCODE_ERROR = 'CLEAR_ZIPCODE_ERROR'

export const SET_ZIPCODE = 'SET_ZIPCODE'
export const SET_ZIPCODE_CALENDAR = 'SET_ZIPCODE_CALENDAR'
export const SET_ZIPCODE_CUTOFF = 'SET_ZIPCODE_CUTOFF'

const setZipcode =
    (zipcode = '') =>
    (dispatch) =>
        dispatch({ type: SET_ZIPCODE, zipcode })

export const getZipcode =
    (zipcode = '') =>
    (dispatch) =>
        fetchZipcode(zipcode)
            .then((response) => {
                dispatch({
                    type: REQUEST_ZIPCODE + '_SUCCESS',
                    zipcode: zipcode,
                    calendar: R.path(['zipcode', zipcode], response),
                    cutoff: R.path(['sameday', 'cutoff'], response),
                })
            })
            .catch((err) => {
                console.log(R.path(['statusText'], err))
                dispatch({
                    type: REQUEST_ZIPCODE + '_FAILED',
                    zipcode: zipcode,
                    error: R.path(['statusText'], err),
                })
            })

const getZipcodeCalendar =
    (zipcode = '') =>
    (dispatch) =>
        fetchZipcodeCalendar(zipcode)
            .then((response) => {
                dispatch({
                    type: SET_ZIPCODE_CALENDAR,
                    calendar: R.path(['zipcode', zipcode], response),
                })
            })
            .catch((err) => {
                dispatch({
                    type: REQUEST_ZIPCODE + '_FAILED',
                    zipcode: zipcode,
                    error: R.path(['statusText'], err),
                })
            })

const getZipcodeCutoff =
    (zipcode = '') =>
    (dispatch) =>
        fetchZipcodeCutoff(zipcode)
            .then((response) => {
                dispatch({
                    type: SET_ZIPCODE_CUTOFF,
                    cutoff: R.path(['sameday', 'cutoff'], response),
                })
            })
            .catch((err) => {
                dispatch({
                    type: REQUEST_ZIPCODE + '_FAILED',
                    zipcode: zipcode,
                    error: R.path(['statusText'], err),
                })
            })

export const getZipcodeBatch =
    (zipcode = '') =>
    (dispatch) => {
        dispatch(setZipcode(zipcode))
        dispatch(getZipcodeCalendar(zipcode))
        dispatch(getZipcodeCutoff(zipcode))
    }

export const clearZipcode = () => (dispatch) => dispatch({ type: CLEAR_ZIPCODE })

export const clearZipcodeError = () => (dispatch) => dispatch({ type: CLEAR_ZIPCODE_ERROR })
