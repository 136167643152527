import * as R from 'ramda'
import { push } from 'connected-react-router'
import { format } from 'date-fns'

import { getRedirectURL } from '../utils/URL'
import { isForeignProduct, isGiftcardByProductId } from '../utils/ProductTypes'

export const CREATE_CART = 'CREATE_CART'
export const GET_CART = 'GET_CART'

export const SAVE_RECIPIENT_INFO_TO_CART = 'SAVE_RECIPIENT_INFO_TO_CART'
export const SAVE_RECIPIENT_ZIP_TO_CART = 'SAVE_RECIPIENT_ZIP_TO_CART'
export const SAVE_RECIPIENT_DATE_AND_TIME_TO_CART = 'SAVE_RECIPIENT_DATE_AND_TIME_TO_CART'
export const SAVE_CARD_TEXT_TO_CART = 'SAVE_CARD_TEXT_TO_CART'
export const SAVE_RIBBON_CONTENT_TO_CART = 'SAVE_RIBBON_CONTENT_TO_CART'
export const SAVE_CONDOLENCES_CONTENT_TO_CART = 'SAVE_CONDOLENCES_CONTENT_TO_CART'
export const SAVE_COURIER_CONTENT_TO_CART = 'SAVE_COURIER_CONTENT_TO_CART'
export const SAVE_SENDER_INFO_TO_CART = 'SAVE_SENDER_INFO_TO_CART'

export const SAVE_ADDITIONAL_PRODUCTS_TO_CART = 'SAVE_ADDITIONAL_PRODUCTS_TO_CART'
export const REMOVE_ALL_ADDITIONAL_PRODUCTS_FROM_CART = 'REMOVE_ALL_ADDITIONAL_PRODUCTS_FROM_CART'

export const SAVE_PRODUCT_TO_CART = 'SAVE_PRODUCT_TO_CART'
export const REMOVE_ALL_PRODUCTS_FROM_CART = 'REMOVE_ALL_PRODUCTS_FROM_CART'
export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART'

export const SAVE_GIFTCARD_TO_CART = 'SAVE_GIFTCARD_TO_CART'

export const SAVE_DELIVERYTERMS_ACCEPTED_TO_CART = 'SAVE_DELIVERYTERMS_ACCEPTED_TO_CART'

export const SAVE_DISCOUNT_CODE_TO_CART = 'SAVE_DISCOUNT_CODE_TO_CART'

export const SET_CART_STEP_VALUE = 'SET_CART_STEP_VALUE'
export const CLEAR_CART_STEP_VALUES = 'CLEAR_CART_STEP_VALUES'

import { clearZipcode } from './zipcodeActions'
import { setInvoiceRedirect } from './invoiceActions'
import { addFormCardText, clearForms } from './formActions'
import { emptyCartAdditionalProduct, unselectCondolences, unselectRibbon } from './orderActions'

import {
    createCart,
    fetchCart,
    saveRecipientInfoToCart,
    saveCardsToCart,
    saveRibbonContentToCart,
    saveCondolencesContentToCart,
    saveCourierContentToCart,
    saveSenderInfoToCart,
    removeAllAdditionalProductsFromCart,
    saveProductToCart,
    removeProductFromCart,
    removeAllProductsFromCart,
    saveGiftcardToCart,
    saveDeliveryTermsAcceptedToCart,
    applyDiscountCodeToCart,
    saveAdditionalProductsToCart,
} from '../api/backend'

export const createNewCart = () => (dispatch) => {
    dispatch({ type: CREATE_CART })
    return createCart()
        .then((results) => {
            dispatch({
                type: CREATE_CART + '_SUCCESS',
                cart: results,
            })
        })
        .then(() => dispatch(_resetForms()))
        .then(() => dispatch(_resetOrderStoreAddOns()))
        .catch((error) =>
            dispatch({
                type: CREATE_CART + '_FAILED',
                error,
            }),
        )
}

const _resetForms = () => (dispatch) => {
    dispatch(setInvoiceRedirect(false))
    dispatch(clearZipcode())
    dispatch(clearForms())
}

const _resetOrderStoreAddOns = () => (dispatch) => {
    dispatch(emptyCartAdditionalProduct())
    dispatch(unselectCondolences())
    dispatch(unselectRibbon())
}

export const getCart = () => (dispatch, getState) => {
    const { lang, cart } = getState()

    const cart_id = R.path(['items', 'cart_id'], cart)
    const language = R.path(['lang'], lang)

    dispatch({ type: GET_CART })
    return fetchCart(cart_id, language)
        .then((cart) => {
            const editable = R.path(['editable'], cart)

            if (editable) {
                return dispatch({
                    type: GET_CART + '_SUCCESS',
                    cart,
                })
            } else {
                // If cart not editable, redirect home and create a new cart
                dispatch(createNewCart())
            }
        })
        .catch((err) => {
            console.error(err)
        })
}

const _saveCartGiftcard = () => (dispatch, getState) => {
    const { cart, form } = getState()
    const cart_id = R.path(['items', 'cart_id'], cart)

    const { giftcardPrice: price, giftcardAmount: amount } = form

    dispatch({ type: SAVE_GIFTCARD_TO_CART })
    return saveGiftcardToCart(cart_id, price, amount).catch((error) => {
        dispatch({
            type: SAVE_GIFTCARD_TO_CART + '_FAILED',
            error,
        })
    })
}

export const addGiftcardToCart = (product_id) => (dispatch, getState) => {
    Promise.all([dispatch(_saveCartProduct(product_id)), dispatch(_saveCartGiftcard())]).then(() => dispatch(getCart()))
}

const _saveCartProduct = (product_id) => (dispatch, getState) => {
    const { cart, lang } = getState()
    const cart_id = R.path(['items', 'cart_id'], cart)
    const translation = R.path(['lang'], lang)

    dispatch({ type: SAVE_PRODUCT_TO_CART })
    return saveProductToCart(cart_id, product_id, translation).catch((error) => {
        dispatch({
            type: SAVE_PRODUCT_TO_CART + '_FAILED',
            error,
        })
    })
}

export const addProductToCart = (product_id) => (dispatch, getState) => {
    Promise.all([dispatch(_saveCartProduct(product_id))]).then(() => dispatch(getCart()))
}

export const _removeCartProduct = (product_id) => (dispatch, getState) => {
    const { cart, lang } = getState()
    const cart_id = R.path(['items', 'cart_id'], cart)
    const translation = R.path(['lang'], lang)

    dispatch({ type: REMOVE_PRODUCT_FROM_CART })
    return removeProductFromCart(cart_id, product_id, translation)
        .then((results) =>
            dispatch({
                type: REMOVE_PRODUCT_FROM_CART + '_SUCCESS',
                cart: results,
            }),
        )
        .catch((error) =>
            dispatch({
                type: REMOVE_PRODUCT_FROM_CART + '_FAILED',
                error,
            }),
        )
}

export const removeCartProduct = (product_id) => (dispatch, getState) => {
    Promise.all([dispatch(_removeCartProduct(product_id))]).then(() => dispatch(getCart()))
}

export const _removeAllCartProducts = () => (dispatch, getState) => {
    const { cart, lang } = getState()
    const cart_id = R.path(['items', 'cart_id'], cart)
    const translation = R.path(['lang'], lang)

    dispatch({ type: REMOVE_ALL_PRODUCTS_FROM_CART })
    return removeAllProductsFromCart(cart_id, translation).catch((error) => {
        dispatch({
            type: REMOVE_ALL_PRODUCTS_FROM_CART + '_FAILED',
            error,
        })
    })
}

export const emptyAllCartProducts = () => (dispatch, getState) => {
    Promise.all([dispatch(_removeAllCartProducts()), dispatch(_removeCartAllAdditionalProducts())]).then(() =>
        dispatch(getCart()),
    )
}

export const removeAllCartAdditionalProducts = () => (dispatch, getState) => {
    Promise.all([dispatch(_removeCartAllAdditionalProducts())]).then(() => dispatch(getCart()))
}

export const _removeCartAllAdditionalProducts = () => (dispatch, getState) => {
    const { cart } = getState()
    const cart_id = R.path(['items', 'cart_id'], cart)

    dispatch({ type: REMOVE_ALL_ADDITIONAL_PRODUCTS_FROM_CART })
    return removeAllAdditionalProductsFromCart(cart_id)
        .then(() => dispatch({ type: REMOVE_ALL_ADDITIONAL_PRODUCTS_FROM_CART + '_SUCCESS' }))
        .catch((error) =>
            dispatch({
                type: REMOVE_ALL_ADDITIONAL_PRODUCTS_FROM_CART + '_FAILED',
                error,
            }),
        )
}

export const saveProductAndGiftcardToCart = (product_id) => (dispatch, getState) => {
    const { lang } = getState()

    const url = R.or(isGiftcardByProductId(product_id), isForeignProduct(lang))
        ? '/order/recipient-info'
        : '/order/add-ons'

    Promise.all([dispatch(_removeAllCartProducts()), dispatch(_removeCartAllAdditionalProducts())])
        .then(() => Promise.all([dispatch(_saveCartProduct(product_id)), dispatch(_saveCartGiftcard())]))
        .then(() => dispatch(getCart()))
        .then(() => dispatch(push(getRedirectURL(lang, url))))
}

export const saveRecipientData = () => async (dispatch, getState) => {
    const { form, cart, order, lang } = getState()

    const {
        recipientName: name,
        recipientAddress: address,
        recipientPhone: phone,
        recipientCompany: company,
        recipientCity: city,
        mortName: mort_name,
        churchName: church_name,
        recipientZip: zipcode,
        churchTime,
        recipientDate: delivery_date,
    } = form
    const church_time = churchTime.replace('.', ':')

    const body = {
        name,
        address,
        phone,
        company,
        city,
        mort_name,
        church_name,
        zipcode,
        church_time,
        delivery_date,
    }

    const cart_id = R.path(['items', 'cart_id'], cart)
    const ribbonSelected = R.path(['ribbon'], order)
    const condolencesSelected = R.path(['condolences'], order)

    let url
    if (ribbonSelected) url = '/order/ribbon'
    else url = condolencesSelected ? '/order/condolences' : '/order/card-text'

    try {
        dispatch({ type: SAVE_RECIPIENT_INFO_TO_CART })
        const response = await saveRecipientInfoToCart(cart_id, {
            ...body,
            delivery_date: format(new Date(delivery_date), 'yyyy-MM-dd'),
        })
        if (response.shipping_address_id) {
            dispatch({ type: SAVE_RECIPIENT_INFO_TO_CART + '_SUCCESS' })
            dispatch(getCart())

            return dispatch(push(getRedirectURL(lang, url)))
        } else {
            dispatch({
                type: SAVE_RECIPIENT_INFO_TO_CART + '_FAILED',
                err,
            })
        }
    } catch (err) {
        dispatch({
            type: SAVE_RECIPIENT_INFO_TO_CART + '_FAILED',
            err,
        })
    }
}

export const saveAddOns = () => async (dispatch, getState) => {
    const { order, cart, lang } = getState()

    const selectedAddOns = R.path(['selectedAddOnProducts'], order)
    const cartId = R.path(['items', 'cart_id'], cart)
    const url = '/order/recipient-info'

    dispatch({ type: SAVE_ADDITIONAL_PRODUCTS_TO_CART })

    try {
        await saveAdditionalProductsToCart(cartId, selectedAddOns)
        await dispatch(getCart())
        dispatch({ type: SAVE_ADDITIONAL_PRODUCTS_TO_CART + '_SUCCESS' })
        dispatch(push(getRedirectURL(lang, url)))
    } catch (error) {
        dispatch({
            type: SAVE_ADDITIONAL_PRODUCTS_TO_CART + '_FAILED',
            error,
        })
    }
}

export const saveCards = () => (dispatch, getState) => {
    const { cards, cart, lang } = getState()
    const cartId = R.path(['items', 'cart_id'], cart)
    const cartProducts = R.path(['items', 'products'], cart)
    const firstProduct = R.head(cartProducts)
    const firstCardText = cards.selectedCards[0].cardText
    dispatch({ type: SAVE_CARD_TEXT_TO_CART })

    const url = isGiftcardByProductId(R.prop('id', firstProduct)) ? `/order/sender-info` : `/order/delivery`

    return saveCardsToCart(cartId, R.path(['selectedCards'], cards))
        .then(() => dispatch(addFormCardText(firstCardText))) // wipe out the summary text or leave in place
        .then(() => dispatch(getCart()))
        .then(() => dispatch(push(getRedirectURL(lang, url))))
        .catch((error) =>
            dispatch({
                type: SAVE_CARD_TEXT_TO_CART + '_FAILED',
                error,
            }),
        )
}

export const saveRibbonContent = () => (dispatch, getState) => {
    const { order, form, lang, cart } = getState()
    const { ribbonNames: names, ribbonWords: words } = form
    const body = { names, words }

    const condolencesSelected = R.path(['condolences'], order)
    const url = condolencesSelected ? '/order/condolences' : '/order/card-text'

    const cart_id = R.path(['items', 'cart_id'], cart)

    dispatch({ type: SAVE_RIBBON_CONTENT_TO_CART })
    return saveRibbonContentToCart(cart_id, body)
        .then(() => dispatch(getCart()))
        .then(() => dispatch(push(getRedirectURL(lang, url))))
        .catch((error) =>
            dispatch({
                type: SAVE_RIBBON_CONTENT_TO_CART + '_FAILED',
                error,
            }),
        )
}

export const saveCondolencesContent = () => (dispatch, getState) => {
    const { form, lang, cart } = getState()
    const { condolenceWords: words, condolenceMortName: mort_name, condolenceSenderNames: sender_names } = form
    const body = { words, mort_name, sender_names }

    const cart_id = R.path(['items', 'cart_id'], cart)

    dispatch({ type: SAVE_CONDOLENCES_CONTENT_TO_CART })
    return saveCondolencesContentToCart(cart_id, body)
        .then(() => dispatch(getCart()))
        .then(() => dispatch(push(getRedirectURL(lang, '/order/card-text'))))
        .catch((error) =>
            dispatch({
                type: SAVE_CONDOLENCES_CONTENT_TO_CART + '_FAILED',
                error,
            }),
        )
}

export const saveCourierContent = () => (dispatch, getState) => {
    const { lang, form, cart } = getState()

    const {
        attachedMessageToCourier: attached_message,
        recipientNotContacted: not_contacted,
        recipientAdvanceNotice: advance_notice,
    } = form

    const cart_id = R.path(['items', 'cart_id'], cart)

    const body = { attached_message, not_contacted, advance_notice }

    dispatch({ type: SAVE_COURIER_CONTENT_TO_CART })
    return saveCourierContentToCart(cart_id, body)
        .then(() => dispatch(getCart()))
        .then(() => dispatch(push(getRedirectURL(lang, '/order/sender-info'))))
        .catch((error) =>
            dispatch({
                type: SAVE_COURIER_CONTENT_TO_CART + '_FAILED',
                error,
            }),
        )
}

/**
 * USE THIS AS A TEMPLATE
 */
export const saveSenderInfo = () => (dispatch, getState) => {
    const { lang, form, cart } = getState()

    const { senderName: name, senderPhone: phone, senderCompany: company, senderEmail: email } = form

    const cart_id = R.path(['items', 'cart_id'], cart)

    const body = {
        name,
        phone,
        company,
        email,
    }

    dispatch({ type: SAVE_SENDER_INFO_TO_CART })
    return saveSenderInfoToCart(cart_id, body) // Save data to backend
        .then(() => dispatch(getCart())) // Get updated cart from backend
        .then(() => dispatch(push(getRedirectURL(lang, '/order/payment')))) // Redirect to next step
        .catch((error) => {
            dispatch({
                type: SAVE_SENDER_INFO_TO_CART + '_FAILED',
                error,
            })
        })
}

export const saveDeliverytermsAccepted = () => (dispatch, getState) => {
    const { form, cart } = getState()

    const { deliveryTerms: delivery_terms_accepted } = form

    const cart_id = R.path(['items', 'cart_id'], cart)

    const body = { delivery_terms_accepted }

    dispatch({ type: SAVE_DELIVERYTERMS_ACCEPTED_TO_CART })
    return saveDeliveryTermsAcceptedToCart(cart_id, body)
        .then(() => dispatch(getCart()))
        .catch((error) => {
            dispatch({
                type: SAVE_DELIVERYTERMS_ACCEPTED_TO_CART + '_FAILED',
                error,
            })
        })
}

export const applyDiscountToCart = (code) => (dispatch, getState) => {
    const { cart } = getState()
    const cart_id = R.path(['items', 'cart_id'], cart)
    dispatch({
        type: SAVE_DISCOUNT_CODE_TO_CART + '_SENT',
    })
    return applyDiscountCodeToCart(cart_id, code)
        .then((discount) =>
            dispatch({
                type: SAVE_DISCOUNT_CODE_TO_CART + '_SUCCESS',
                discount,
            }),
        )
        .then(() => dispatch(getCart()))
        .catch((error) =>
            dispatch({
                type: SAVE_DISCOUNT_CODE_TO_CART + '_FAILED',
                error: error,
                code: Number(R.path(['statusText'], error)),
            }),
        )
}

export const clearDiscountError = () => (dispatch) =>
    dispatch({
        type: SAVE_DISCOUNT_CODE_TO_CART + '_FAILED',
        error: null,
        code: null,
    })

export const STEP = {
    NOT_READY: 'not_ready',
    IN_PROGRESS: 'in_progress',
    ERROR: 'error',
    SUCCESS: 'success',
    DONE: 'done',
}
